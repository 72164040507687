import React from "react"

import * as styles from "./index.module.scss"

interface propsType {
    children: string
}

export default function HeadLine({ children }: propsType) {
    return <span className={styles.headLine}>{children} </span>
}