// extracted by mini-css-extract-plugin
export var box = "index-module--box--9566c";
export var effectBoxContainer = "index-module--effectBoxContainer--0d4e4";
export var hiddenText = "index-module--hiddenText--dc8eb";
export var hover_show = "index-module--hover_show--4a635";
export var icon = "index-module--icon--56925";
export var intro = "index-module--intro--cc77d";
export var intro_content = "index-module--intro_content--68dc0";
export var intro_title = "index-module--intro_title--d3aba";
export var linkSolution = "index-module--linkSolution--a96ba";
export var link_footer = "index-module--link_footer--723e8";
export var main = "index-module--main--387e3";
export var mask = "index-module--mask--51dc5";
export var mobile_item = "index-module--mobile_item--1e70d";
export var showText = "index-module--showText--24e3f";
export var slide = "index-module--slide--b0711";
export var title = "index-module--title--82cc6";