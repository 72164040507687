// extracted by mini-css-extract-plugin
export var big = "index-module--big--1dee7";
export var globalPartner = "index-module--globalPartner--022f6";
export var globalPartner_main = "index-module--globalPartner_main--f7db2";
export var innerBox = "index-module--innerBox--e6ca1";
export var second_title = "index-module--second_title--4c868";
export var shuffling = "index-module--shuffling--97ddc";
export var shuffling_box = "index-module--shuffling_box--ffc70";
export var shuffling_container = "index-module--shuffling_container--9af4e";
export var spread = "index-module--spread--9ec67";
export var title = "index-module--title--cb5be";