//首页banner
import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Row, Col } from "antd"

import * as styles from "./index.module.scss"

export default function Banner() {
  const data = useStaticQuery(graphql`
    query aboutQuery {
      banner: file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
      bannerMobile: file(relativePath: { eq: "bannerMobile.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
    }
  `)

  const image = getImage(data.banner.childImageSharp.gatsbyImageData)
  const bannerMobile = getImage(
    data.bannerMobile.childImageSharp.gatsbyImageData
  )

  return (
    <div className={styles.banner}>
      <Row>
        <Col md={24} sm={0} xs={0}>
          {/* pc */}
          {image && <GatsbyImage image={image} alt="banner" />}
          <div className={styles.text}>
            <h1>Zero Miracle跨境，从此开辟您的环球盛名</h1>
            <h3>跨境电商出海护航的行业巨擘</h3>
          </div>
        </Col>
        <Col md={0} sm={24} xs={24}>
          {/* mobile */}
          {bannerMobile && <GatsbyImage image={bannerMobile} alt="banner" />}
          <div className={styles["text_mobile"]}>
            <h1>
              Zero Miracle跨境，
              <br />
              从此开辟您的环球盛名
            </h1>
            <h3>跨境电商出海护航的行业巨擘</h3>
          </div>
        </Col>
      </Row>
    </div>
  )
}
